<template>
    <standard-page title="Templates">
        <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
            <span><router-link :to="{ name: 'identities-catalogs'}">Catalogs</router-link></span>
            <span v-if="catalogName">CATALOG: {{catalogName | truncate(20, '.....')}}</span>
            <span>Templates</span>
        </template>
        <template v-slot:buttons>
            <span class="d-none d-xl-block">
            <button @click="newTemplate" class="d-none d-xl-block btn float-right btn-orange rounded font-weight-bold" title="Add Catalog">
                <i class="fas fa-plus-square mr-2"/>Add Template
            </button>
            <button class="d-xl-none w-35 btn btn-icon float-right btn-orange rounded font-weight-bold" title="Add Catalog">
                <i class="typcn typcn-document-add"/>
            </button>
            </span>
        </template>
        <template v-slot:content>
            <div class="az-content-label mg-b-5">DID Templates</div>
            <p class="mg-b-10">A Decentralized identifiers (DIDs) template is a set of custom parameters defined by business that forms a verifiable, decentralized digital identity. A DID refers to any subject (e.g., a person, organization, thing, data model, abstract entity, etc.) as determined by the controller of the DID.</p>
            <data-table :api="api" :columns="columns" :row-click="view" @ready="onReady" list-key="templates"></data-table>

            <div class="az-content-label mg-b-5 mg-t-50">Claim Templates</div>
            <p class="mg-b-10">This specification template provides a standard way to express credentials for a DID in a way that is cryptographically secure, privacy respecting, and machine-verifiable. A verifiable credential can represent all of the same information that a physical credential represents such as certification information, trade information etc. that are verified by a third party.</p>
            <data-table :api="claim_template_api" :columns="claim_template_columns" :row-click="view" @ready="onReadyClaimTemplate" list-key="templates" table-id="claim_template_table"
                        total-key="total">
            </data-table>

            <div class="modal" id="confirmationmodal">
                <div class="modal-dialog" role="document">
                    <div class="modal-content modal-content-demo">
                        <div class="modal-body tx-center pd-y-20 pd-x-20">
                            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                                <span aria-hidden="true">×</span>
                            </button>
                            <i class="icon ion-ios-warning tx-100 tx-indigo lh-1 mg-t-20 d-inline-block"></i>
                            <h4 class="tx-indigo mg-b-20">Warning!</h4>
                            <p class="mg-b-20 mg-x-20">Are you sure you want to delete the Template?</p>
                            <button @click="deleteTemplate()" class="btn rounded btn-az-primary pd-x-25 mg-t-15" data-dismiss="modal" type="button">
                                Continue
                            </button>
                        </div>
                    </div><!-- modal-content -->
                </div><!-- modal-dialog -->
            </div>
        </template>
    </standard-page>
</template>

<script>
    import EventBus from "@/event-bus";
    import XLSX from "xlsx";

    export default {
        name: "Templates",
        props: ['catalogid', 'catalogNameProp'],
        data() {
            return {
                api: `${this.$identity.defaults.baseURL}/identity/catalogs/${this.catalogid}/templates?template_type=Did`,
                claim_template_api: `${this.$identity.defaults.baseURL}/identity/catalogs/${this.catalogid}/templates?template_type=Claim`,
                columns: [
                    {type: 'name'},
                    {type: 'created'},
                    {type: 'allow_custom'},
                    {
                        type: 'action',
                        defaultContent: `<div class="offset-2 btn-group btn-icon-list">
                                            <span class="text-decoration-none text-danger delete" data-target="#confirmationmodal" data-toggle="modal" style="cursor: pointer">
                                                <i class="fas fa-trash-alt fa-lg" title="Delete DID Template"></i>
                                            </span>
                                            <span class="text-decoration-none text-info export_did_template mg-l-10" style="cursor: pointer">
                                                <i class="fas fa-file-export fa-lg" title="Export DID Template"></i>
                                            </span>
                                        </div>`
                    },
                    {type: 'toggle-active', data: 'is_active'}
                ],
                claim_template_columns: [
                    {type: 'name'},
                    {type: 'created'},
                    {type: 'allow_custom'},
                    {
                        type: 'action',
                        defaultContent: `<div class="offset-2 btn-group btn-icon-list">
                                            <span class="text-decoration-none text-danger delete_claim_template" data-target="#confirmationmodal" data-toggle="modal" style="cursor: pointer">
                                                <i class="fas fa-trash-alt fa-lg" title="Delete Claim Template"></i>
                                            </span>
                                            <span class="text-decoration-none text-info export_claim_template mg-l-10" style="cursor: pointer">
                                                <i class="fas fa-file-export fa-lg" title="Export Claim Template"></i>
                                            </span>
                                        </div>`,
                    },
                ],
                table: null,
                catalog: {
                    name: null
                },
                catalogName: this.catalogNameProp,
                templateIdToDelete: null,
            };
        },
        mounted() {
            this.getCatalog()
        },
        methods: {
            async getCatalog() {
                try {
                    let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}`);
                    this.catalogName = reply.data ? reply.data.name : null;
                } catch (e) {
                    this.$toastr.e("Error getting catalog name", "Error");
                }
            },
            onReady(table) {
                this.table = table;
                let self = this;

                $(async function () {
                    $('#main-table tbody').on('click', '.delete', function () {
                        let entity = table.row($(this).parents('tr')).data();
                        self.templateIdToDelete = null;
                        self.setTemplateIdToDelete(entity.template);
                        table.ajax.reload();
                    });

                    $('#main-table tbody').on('click', '.enable-template', async function (e) {
                        e.stopPropagation();
                        let row = table.row($(this).parents('tr')).data();
                        await self.enableDisableTemplate(row.template, false);
                        table.ajax.reload();
                    });

                    $('#main-table tbody').on('click', '.disable-template', async function (e) {
                        e.stopPropagation();
                        let row = table.row($(this).parents('tr')).data();
                        await self.enableDisableTemplate(row.template, true);
                        table.ajax.reload();
                    });

                    $('#main-table tbody').on('click', '.export_did_template', async function (e) {
                        e.stopPropagation();
                        let row = table.row($(this).parents('tr')).data();
                        await self.exportTemplateAsExcel(row.template, row.name);
                    });

                });
            },
            onReadyClaimTemplate(table) {
                this.table = table;
                let self = this;

                $('#claim_template_table tbody').on('click', '.delete_claim_template', function () {
                    let entity = table.row($(this).parents('tr')).data();
                    self.templateIdToDelete = null;
                    self.setTemplateIdToDelete(entity.template);
                    table.ajax.reload();
                });

                $('#claim_template_table tbody').on('click', '.export_claim_template', async function (e) {
                    e.stopPropagation();
                    let row = table.row($(this).parents('tr')).data();
                    await self.exportTemplateAsExcel(row.template, row.name);
                });
            },
            async enableDisableTemplate(templateid, flag) {
                try {
                    EventBus.$emit('openLoader');
                    await this.$identity.put(`/identity/catalogs/${this.catalogid}/templates/${templateid}`, {
                        enable_flag: flag
                    });
                    this.table.ajax.reload();
                    this.$toastr.s("DID template status updated", "Success");
                } catch (e) {

                } finally {
                    EventBus.$emit('closeLoader');
                }
            },
            newTemplate() {
                this.$router.push({name: 'new-template'});
            },
            view(did_template) {
                this.$router.push({name: 'view-and-edit-template', params: {templateid: did_template.template, templatetype: did_template.template_type}});
            },
            setTemplateIdToDelete(templateid) {
                this.templateIdToDelete = templateid;
            },
            async deleteTemplate() {
                try {
                    EventBus.$emit('openLoader');
                    await this.$identity.delete(`identity/catalogs/${this.catalogid}/templates/${this.templateIdToDelete}`);
                    this.$toastr.s(`Template Successfully deleted!`, 'Success');
                    this.$router.go();
                } catch (e) {
                    this.$toastr.e(`Template deletion failed`, 'Error');
                } finally {
                    EventBus.$emit('closeLoader');
                }
            },
            async exportTemplateAsExcel(templateid, template_name) {
                try {
                    let {data} = await this.$identity.get(`identity/catalogs/${this.catalogid}/templates/${templateid}`);

                    let workbook = XLSX.utils.book_new();
                    let worksheet = XLSX.utils.json_to_sheet(data);
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');
                    XLSX.writeFile(workbook, `${template_name}.xlsx`);
                } catch (e) {
                    console.error(e)
                    this.$toastr.e('Export failed', 'Failure');
                } finally {
                }
            },
        }
    }
</script>

<style scoped>

</style>